// @flow
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import {
  make_simple_selectors,
  make_reducer_n_actions,
  make_simple_reducer,
} from "redux_helpers";
import get from "lodash/get";
import find from "lodash/find";
import mapValues from "lodash/mapValues";
import reduce from "lodash/reduce";
import dayjs from "dayjs";
import {
  DEFAULT_CURRENCY,
  SUBSCRIPTION_PERIOD_ANNUAL,
  SUBSCRIPTION_TYPE_FREE,
  SUBSCRIPTION_TYPE_BASIC,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_FUNDAMENTALS,
} from "helpers/constants";

import processFeatures from "redux/helpers/processFeatures";
import type {
  PlanDiscountInfo,
  PlanInfoState,
  PlanDetails,
} from "./plan_info_types";

export const MONTHLY_DISCOUNT_PRICE = 29;
export const ANNUAL_DISCOUNT_PRICE = 22.91;
export const ANNUAL_PRICE = 275;
export const MONTHS = 12;
const SCHOLARSHIP_PAGE_TYPE = "SCHOLARSHIP";
const PARTNERSHIP_PAGE_TYPE = "PARTNERSHIP";
const PAYMENT_PAGE_TYPE = "PAYMENT";
const ALL_PAGES_TYPE = "ALL_PAGES";
const QUANTIZATION = {
  annual: "year",
  monthly: "month",
  lifetime: "",
};

// -------
// Initial State
// --------

const discountInitialState: PlanDiscountInfo = Immutable<PlanDiscountInfo>({
  valid: false,
  amount_off: null,
  percent_off: null,
  duration_type: "",
  description: "",
  tag_line: "",
});

function computeSavings(originalRate: number, discountRate: number): number {
  return Math.floor(((originalRate - discountRate) / originalRate) * 100);
}

/**
 * Define temporary constants for plan information
 * that should come from backend soon
 */
const basicPlanDetails = {
  name: "",
  label: "",
  description: "",
  discount_info: discountInitialState,
  savings: 0,
  by_month_full: MONTHLY_DISCOUNT_PRICE,
  period_months: 1,
  isLifetime: null,
  quantizedPeriod: "",
  renewsPeriod: "",
  features: [
    { text: "Limited access to the catalog" },
    { text: "Limited access to 16 guided projects" },
    { text: "Unlock all 100+ practice problems" },
    { text: "Lifetime community membership" },
    { text: "Priority community support" },
  ],
};

const freePlanDetails: PlanDetails = {
  name: "",
  label: "",
  description: "",

  discount_info: discountInitialState,
  by_month: 0,
  discounted: null,
  by_month_full: 0,
  period_months: 0,
  quantizedPeriod: "",
  renewsPeriod: "",
  savings: 0,
  isLifetime: null,
  features: [
    { text: "Try the first 3 lessons of your path" },
    { text: "Explore the practice problems and project catalog" },
  ],
};

const premiumPlanDetails = {
  name: "",
  label: "",
  description:
    "Learn in-demand data skills, build your portfolio, and reach your career goals!",
  discount_info: discountInitialState,
  savings: 0,
  by_month: 0,
  discounted: null,
  quantizedPeriod: "",
  renewsPeriod: "",
  by_month_full: 0,
  period_months: 0,
  isLifetime: null,
  features: [
    {
      text:
        "Unlock the full catalog - access all paths, courses, practice sets, and projects",
    },
    {
      text:
        "Achieve your learning goals 4x faster with Dataquest's Teaching method",
    },
    { text: "Get personalized help from Chandra AI" },
  ],
};

const premiumExtraDetails = {
  monthly: {
    altDescription: "From zero to job ready",
    billingInfo: "Billed monthly at",
    quantizedPeriod: "month",
    renewsPeriod: "monthly",
    promoInfo: "10% off coupon applied",
  },
  annual: {
    altDescription: "From zero to job ready with 12 month of premium",
    billingInfo: "Billed annually at",
    quantizedPeriod: "year",
    renewsPeriod: "annually",
    promoInfo: "50% off coupon applied",
  },
  lifetime: {
    altDescription: "Premium lifetime membership",
    billingInfo: "Pay once, learn for lifetime",
    promoInfo: "40% off coupon applied",
  },
};

const fundamentalsPlanDetails = {
  name: "",
  label: "",
  description:
    "Develop basic programming skills, and get started in data science!",
  discount_info: discountInitialState,
  savings: 0,
  by_month: 0,
  discounted: null,
  by_month_full: 0,
  period_months: 0,
  isLifetime: null,
  quantizedPeriod: "",
  renewsPeriod: "",
  features: [
    {
      text: "Complete access to all fundamental courses",
      highlights: ["14 Courses"],
    },
    { text: "Complete access to 8 guided projects" },
    { text: "Complete access to 250+ practice problems" },
    { text: "Certificates of course completion" },
    { text: "Lifetime community membership" },
  ],
};

export const initial_state: PlanInfoState = {
  planPricingLoaded: false,
  currencyList: [],
  paymentFeaturesLoaded: false,
  paymentFeatures: [],
  defaultCurrency: "",
  preferredCurrency: "",
  plans: {
    free: {
      title: "Free",
      plan_name: SUBSCRIPTION_TYPE_FREE,
      switch_to: SUBSCRIPTION_TYPE_PREMIUM,
      monthly: {
        ...freePlanDetails,
        name: "Monthly",
        label: "1-Month",
      },
      quarterly: {
        ...freePlanDetails,
        name: "3-Month",
        label: "3-Month",
      },
      annual: {
        ...freePlanDetails,
        name: "Annual",
        label: "12-Month",
      },
    },
    premium: {
      title: "Premium",
      plan_name: SUBSCRIPTION_TYPE_PREMIUM,
      switch_to: SUBSCRIPTION_TYPE_FUNDAMENTALS,
      monthly: {
        ...premiumPlanDetails,
        name: "Monthly",
        label: "1-Month",
      },
      quarterly: {
        ...premiumPlanDetails,
        name: "3-Month",
        label: "3-Month",
      },
      annual: {
        ...premiumPlanDetails,
        name: "Annual",
        label: "12-Month",
        savings: null,
        renewalPrice: null,
      },
      lifetime: {
        ...premiumPlanDetails,
        name: "Lifetime",
        label: "Lifetime",
        savings: null,
        renewalPrice: null,
      },
    },
    fundamentals: {
      title: "Fundamentals",
      plan_name: SUBSCRIPTION_TYPE_FUNDAMENTALS,
      switch_to: SUBSCRIPTION_TYPE_PREMIUM,
      monthly: {
        ...fundamentalsPlanDetails,
        name: "Monthly",
        label: "1-Month",
      },
      quarterly: null,
      annual: null,
    },
    basic: {
      title: "Basic",
      plan_name: SUBSCRIPTION_TYPE_BASIC,
      switch_to: SUBSCRIPTION_TYPE_PREMIUM,
      monthly: {
        ...basicPlanDetails,
        name: "Monthly",
        label: "1-Month",
        by_month: MONTHLY_DISCOUNT_PRICE,
        discounted: null,
        price: MONTHLY_DISCOUNT_PRICE,
      },
      annual: {
        ...basicPlanDetails,
        name: "Annual",
        label: "12-Month",
        by_month: ANNUAL_DISCOUNT_PRICE,
        discounted: ANNUAL_DISCOUNT_PRICE,
        price: ANNUAL_PRICE,
        period_months: 12,
      },
    },
  },
  discount_state: {
    code: "",
    checking: false,
    unknown: false,
  },
  couponInit: "",
  is_yearly: true,
  selected_plan: SUBSCRIPTION_TYPE_PREMIUM,
  selectedPeriod: SUBSCRIPTION_PERIOD_ANNUAL,
  timed_discount: {
    code: "may19_promo",
    start: "2019-03-18T00:00:00-05:00",
    end: "2019-06-03T23:59:59",
    extended: "2019-06-05T23:59:59",
  },
};

// -------
// Selectors
// --------
const BASE = "plan_info";
export { BASE as BASE_SELECTOR_PATH };

// $FlowFixMe
const simpleSelectors = make_simple_selectors(initial_state, BASE);

const doesPlanDiscountHaveValid = ({ monthly, annual, lifetime }) => {
  if (!monthly) return false;
  if (!annual && !lifetime) return monthly.discount_info.valid;
  return (
    monthly.discount_info.valid ||
    annual.discount_info.valid ||
    lifetime?.discount_info?.valid
  );
};

// $FlowFixMe
const all_plans_discount_invalid = createSelector(
  simpleSelectors.plans,
  plans =>
    !reduce(
      plans,
      (valid, plan) => valid || doesPlanDiscountHaveValid(plan),
      false,
    ),
);

// $FlowFixMe
const selected_plan = createSelector(
  simpleSelectors.plans,
  simpleSelectors.selected_plan,
  (plans, selected) => plans[selected],
);

// $FlowFixMe
const discount_invalid = createSelector(
  selected_plan,
  plan => (plan ? !doesPlanDiscountHaveValid(plan) : false),
);

// $FlowFixMe
const discount_info_selector = createSelector(
  simpleSelectors.selectedPeriod,
  selected_plan,
  (selectedPeriod, selectedPlan) =>
    selectedPlan && selectedPlan[selectedPeriod]
      ? selectedPlan[selectedPeriod].discount_info
      : {},
);

// $FlowFixMe
const any_discount_info = createSelector(
  simpleSelectors.selectedPeriod,
  simpleSelectors.plans,
  (selectedPeriod, plans) => {
    const plan = plans[selectedPeriod];
    return get(plan, [selectedPeriod, "discount_info"], discountInitialState);
  },
);

export const selectors = {
  ...simpleSelectors,
  all_plans_discount_invalid,
  selected_plan,
  discount_invalid,
  discount_info: discount_info_selector,
  any_discount_info,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "plan_info/";

const public_handlers = {
  updateDiscountCode: (state, { payload }) => {
    if (state.discount_state.code === payload) return state;

    return state.merge({
      discount_state: {
        code: payload,
        unknown: true,
        checking: false,
      },
    });
  },
  setInitialCoupon: (state, { payload }) => {
    if (state.discount_state.code === payload || state.couponInit === payload)
      return state;

    return state.merge({
      couponInit: payload,
    });
  },
  clearDiscountCode: (state, { payload }) => {
    const plans = {};
    plans[state.selected_plan] = mapValues(
      payload,
      (periodDiscounts, plan_name) => {
        if (!periodDiscounts) return;
        if (periodDiscounts.constructor === Object) {
          const periodInfo = { ...periodDiscounts };
          periodInfo.discount_info = {
            valid: false,
          };
          periodInfo.discounted = null;
          periodInfo.savings = null;
          return periodInfo;
        }
        return periodDiscounts;
      },
    );

    return state.merge(
      {
        plans,
        discount_state: {
          code: "",
          unknown: true,
          checking: false,
        },
      },
      { deep: true },
    );
  },
  changePlan: make_simple_reducer("selected_plan"),
  setYearly: make_simple_reducer("is_yearly"),
  setSelectedPeriod: make_simple_reducer("selectedPeriod"),
  changePlanAndPeriod: (state, { payload }) =>
    state.merge({
      selected_plan: payload.planType,
      selectedPeriod: payload.period,
    }),
};

function computeRate(originalRate, discount) {
  if (discount.amount_off) {
    return originalRate - discount.amount_off;
  }
  if (discount.percent_off) {
    return originalRate * (1 - discount.percent_off);
  }
  return originalRate;
}

function addDiscount({
  by_month,
  is_monthly,
  is_lifetime,
  discount_info,
  original_monthly_rate,
  period_months,
  renewalPrice,
}) {
  let discounted;
  if (is_monthly) {
    discounted = computeRate(by_month, discount_info);
  } else if (is_lifetime) {
    discounted = computeRate(by_month * period_months, discount_info);
  } else {
    discounted =
      computeRate(by_month * period_months, discount_info) / period_months;
  }

  return {
    discount_info: {
      valid: true,
      end_date: discount_info.end_date
        ? new Date(discount_info.end_date)
        : null,
      ...discount_info,
    },
    // renewalPrice:
    //   nextRenewalDiscounted && discount_info?.valid ? discounted : renewalPrice,
    renewalPrice,
    renewalPricePeriod: discount_info?.period,
    discounted,
    savings: computeSavings(original_monthly_rate, discounted),
  };
}

const private_handlers = {
  updatePaymentFeatures: (state, { payload }) => {
    const paymentFeatures = [];
    payload.forEach(features => {
      if (dayjs(features.end) > dayjs(Date.now()))
        paymentFeatures.push({
          ...features,
          features: processFeatures(features.features),
          pageTagline: features.page_tagline,
          allPages: features.page_type === ALL_PAGES_TYPE,
          scholarship: features.page_type === SCHOLARSHIP_PAGE_TYPE,
          partnership: features.page_type === PARTNERSHIP_PAGE_TYPE,
          payment: features.page_type === PAYMENT_PAGE_TYPE,
        });
    });

    return state.merge({
      paymentFeaturesLoaded: true,
      paymentFeatures,
    });
  },
  setDiscounts: (state, { payload }) => {
    const discount_state = {
      checking: false,
      code: state.discount_state.code,
    };
    const plans = mapValues(payload, (periodDiscounts, plan_name) => {
      const periodWithLimitedOffer = find(payload[plan_name], "limited_offer");

      if (periodWithLimitedOffer) {
        discount_state.code = periodWithLimitedOffer.code;
      }
      const discountInfo = {};
      Object.keys(periodDiscounts).forEach(period => {
        const discount = periodDiscounts[period];
        state.currencyList.forEach(currency => {
          const currencyPeriod = currency.default
            ? period
            : `${period}-${currency.code.toLowerCase()}`;
          const planPeriod = state.plans[plan_name][currencyPeriod];
          if (planPeriod) {
            let currencyDiscount = discount[currency.code];
            if (currencyDiscount) {
              currencyDiscount = {
                ...discount,
                ...currencyDiscount,
              };
            } else currencyDiscount = { ...discountInitialState };

            discountInfo[currencyPeriod] = addDiscount({
              by_month: planPeriod.by_month,
              renewalPrice: planPeriod.renewalPrice,
              discount_info: currencyDiscount,
              is_monthly: planPeriod.billing_period === "monthly",
              is_lifetime: planPeriod.is_lifetime,
              original_monthly_rate: planPeriod.by_month_full,
              period_months: planPeriod.period_months,
            });
          }
        });
      });
      return discountInfo;
    });

    return state.merge(
      {
        discount_state,
        plans,
      },
      { deep: true },
    );
  },
  checkingDiscount: (state, { payload }) =>
    state.merge(
      {
        discount_state: {
          checking: true,
          unknown: false,
          recentUid: payload.uid,
        },
      },
      { deep: true },
    ),
  noTimedDiscount: state =>
    state.merge(
      {
        discount_state: {
          checking: false,
        },
      },
      { deep: true },
    ),
  prefillCode: (state, { payload }) => {
    if (state.discount_state.code === payload.code) return state;
    return state.merge({
      plans: state.plans,
      discount_state: {
        checking: false,
        unknown: true,
        prefilled: true,
        ...payload,
      },
    });
  },

  updatePlanInfo: (state, { payload }) => {
    const plans = payload.pricing_plans;
    const plansByTypes = {};

    plans.forEach(plan => {
      const planType = plan.app_label.toLowerCase();
      const currency = plan.currency.toLowerCase();
      const shortPlanName = plan.name.replace(`${planType}-`, "");
      const defaultPlanName = plan.name
        .replace(`-${currency}`, "")
        .replace(`${planType}-`, "");
      const defaultPlan = state.plans[planType][defaultPlanName];

      if (!plansByTypes[planType])
        plansByTypes[planType] = { ...state.plans[planType] };

      plansByTypes[planType][shortPlanName] = {
        ...premiumPlanDetails,
        ...premiumExtraDetails[plan.billing_period],
        discount_info: {},
        discounted: null,
        planType,
        features: defaultPlan ? defaultPlan.features : [],
        ...state.plans[planType][shortPlanName],
        ...plan,
        renewalPrice: plan.price,
        name: plan.verbose_name.replace(plan.app_label, "").trim(),
        label: plan.is_lifetime ? "Lifetime" : `${plan.period_months}-Month`,
        isLifetime: plan.is_lifetime,
        quantizedPeriod: QUANTIZATION[plan.billing_period],
      };
    });

    return state.merge(
      {
        planPricingLoaded: true,
        plans: { ...plansByTypes },
      },
      { deep: true },
    );
  },

  setCurrencyList: (state, { payload }) => {
    const defaultCurrency = find(payload.currencies, { default: true });
    const preferredCurrency = find(payload.currencies, {
      code: payload.preferredCurrency,
    });

    return state.merge({
      currencyList: payload.currencies,
      defaultCurrency: defaultCurrency
        ? defaultCurrency.code
        : DEFAULT_CURRENCY,
      preferredCurrency: preferredCurrency ? preferredCurrency.code : null,
    });
  },
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
  // $FlowFixMe
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
